<template>
  <div>
    <b-alert
      v-if="proposedActions.length < 1"
      show
      dismissible
      fade
      class="mt-1"
      variant="warning"
    >
      <div class="alert-body">
        Debe al menos registrar una accion propuesta
      </div>
    </b-alert>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Lists.Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('Lists.Entries') }}</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Lists.Search')"
            />
            <b-button
              variant="primary"
              @click="onNewProposedAction"
            >
              <span class="text-nowrap">Nueva accion propuesta</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refProposedActionsTable"
      responsive
      :items="refetchProposedActions"
      :fields="proposedActionsFields"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="onSelectProposedAction(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onDeleteProposedAction(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProposedActions"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-if="proposedActionsSelected"
      id="modal-proposedActions"
      v-model="showProposedActionsModal"
      title="Nueva accion propuesta"
      size="xl"
    >
      <validation-observer
        ref="refProposedActionsRules"
        tag="form"
      >
        <b-row>
          <!-- Propose actions input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Acciones-propuestas"
              rules="required"
            >
              <b-form-group
                label="Accion propuesta"
              >
                <b-form-input
                  v-model="proposedActionsSelected.descripcion"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- employee select -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="empleado"
              rules="required"
            >
              <b-form-group
                label="Empleado responsable"
              >
                <v-select
                  id="empleado-responsable"
                  v-model="proposedActionsSelected.empleadoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="employees"
                  :state="errors.length > 0 ? false:null"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- isContinuous input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Es-Continua"
              rules="required"
            >
              <b-form-group
                label="Continua"
              >
                <b-form-checkbox
                  v-model="proposedActionsSelected.esContinua"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- start date input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Fecha-inicio"
              rules="required"
            >
              <b-form-group
                label="Fecha de inicio"
              >
                <b-form-datepicker
                  id="fecha-inicio"
                  v-model="proposedActionsSelected.fechaInicio"
                  today-button
                  reset-button
                  close-button
                  locale="es"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- finish date input -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Fecha cierre"
            >
              <b-form-datepicker
                id="fecha-cierre"
                v-model="proposedActionsSelected.fechaCierre"
                :disabled="proposedActionsSelected.esContinua"
                today-button
                reset-button
                close-button
                locale="es"
              />
            </b-form-group>
          </b-col>
          <!-- notes results input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Notas-Resultado"
              rules="required"
            >
              <b-form-group
                label="Notas Resultado"
              >
                <b-form-input
                  v-model="proposedActionsSelected.notasResultado"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Result input -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Resultado"
            >
              <b-form-input
                v-model="proposedActionsSelected.resultado"
                :disabled="proposedActionsSelected.esContinua"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="proposedActionsSelected.requerimientoPropuestaPrevencionFuturaId === 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationProposedActions(() => {
              onAddProposedAction()
            })"
          >
            Guardar accion propuesta
          </b-button>
          <b-button
            v-if="proposedActionsSelected.requerimientoPropuestaPrevencionFuturaId > 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationProposedActions(() => {
              onEditProposedAction()
            })"
          >
            Actualizar accion propuesta
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  // BFormTextarea,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import requirementService from '@/services/requirement.service'
import i18n from '@/libs/i18n'

export default {

  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BAlert,
    BFormCheckbox,
    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    requerimientoId: {
      type: Number,
      required: true,
    },
    proposedActionCount: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Refs */
    const {
      fetchProposedActions,
      createProposedActions,
      deleteProposedActions,
      updateProposedActions,
      fetchEmployeeOptions,
    } = requirementService()
    const refProposedActionsTable = ref(null)
    const refProposedActionsRules = ref(null)

    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const searchQuery = ref('')
    const sortBy = ref('requerimientoPropuestaPrevencionFuturaId')
    const isSortDirDesc = ref(false)
    /* Data */
    const totalProposedActions = ref(0)
    const proposedActions = ref([])
    const employees = ref([])

    const blankProposedActionsData = {
      requerimientoPropuestaPrevencionFuturaId: 0,
      requerimientoId: props.requerimientoId,
      numero: '1',
      descripcion: '',
      empleadoId: null,
      fechaInicio: '',
      fechaCierre: '',
      notasResultado: '',
      resultado: null,
      esContinua: true,
    }

    const proposedActionsSelected = ref(JSON.parse(JSON.stringify(blankProposedActionsData)))
    const proposedActionsFields = [
      {
        key: 'numero',
        label: 'Numero',
        sortable: false,
      },
      {
        key: 'descripcion',
        label: 'Acciones para prevenir',
        sortable: false,
      },
      {
        key: 'empleado.nombreEmpleado',
        label: 'Responsable',
        sortable: false,
      },
      {
        key: 'fechaInicio',
        label: 'Fecha inicio',
        sortable: false,
      },
      {
        key: 'fechaCierre',
        label: 'Fecha cierre',
        sortable: false,
      },
      {
        key: 'resultado',
        label: 'Resultado',
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]
    fetchEmployeeOptions(data => {
      employees.value = data
    })
    const refetchProposedActions = (ctx, callback) => {
      fetchProposedActions(props.requerimientoId, {
        searchQuery: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      }, data => {
        proposedActions.value = data.propuestasPrevencionFutura
        totalProposedActions.value = data.total
        emit('update:proposed-action-count', totalProposedActions.value)
        callback(proposedActions.value)
      })
    }

    const dataMeta = computed(() => {
      const localItemsCount = refProposedActionsTable.value ? refProposedActionsTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalProposedActions.value,
      }
    })

    const refreshData = () => {
      refProposedActionsTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refreshData()
    })

    /* UI */
    const showProposedActionsModal = ref(false)
    const savingChanges = ref(false)

    const onNewProposedAction = () => {
      proposedActionsSelected.value = JSON.parse(JSON.stringify(blankProposedActionsData))
      showProposedActionsModal.value = true
    }
    const onAddProposedAction = () => {
      savingChanges.value = true
      createProposedActions(proposedActionsSelected.value, data => {
        proposedActionsSelected.value = data
        showProposedActionsModal.value = false
        savingChanges.value = false
        refreshData()
      })
    }
    const onSelectProposedAction = item => {
      proposedActionsSelected.value = item
      showProposedActionsModal.value = true
    }

    const onEditProposedAction = () => {
      savingChanges.value = true
      updateProposedActions(proposedActionsSelected.value)
        .then(() => {
          savingChanges.value = false
          showProposedActionsModal.value = false
        })
    }
    const onDeleteProposedAction = item => {
      deleteProposedActions(item.requerimientoPropuestaPrevencionFuturaId, () => {
        refreshData()
      })
    }
    const {
      getValidationState,
    } = formValidation()

    const validationProposedActions = callback => {
      refProposedActionsRules.value.validate().then(success => {
        if (success) {
          callback()
        }
      })
    }
    return {
      refProposedActionsTable,
      refProposedActionsRules,

      perPageOptions,
      perPage,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      proposedActionsSelected,
      proposedActionsFields,
      proposedActions,
      totalProposedActions,
      employees,
      dataMeta,

      refetchProposedActions,

      required,
      getValidationState,

      showProposedActionsModal,

      savingChanges,

      onNewProposedAction,
      onAddProposedAction,
      onSelectProposedAction,
      onEditProposedAction,
      onDeleteProposedAction,
      validationProposedActions,
    }
  },
}
</script>

<style>

</style>
