<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in requirementsLogs"
      :key="item.id"
      :variant="resolveVariant(item.detalle)"
    >
      <div>
        {{ item.detalle }}
      </div>
      <div v-if="item.detalle === 'Modificación\r\n'">
        Cambio {{ item.campo }}: de '{{ item.valorAnterior }}' a '{{ item.valorActual }}' (ID {{ item.tablaId }}).
      </div>
      <div v-if="item.detalle === 'Creación\r\n' || item.detalle === 'Eliminación\r\n'">
        ID {{ item.tablaId }}
      </div>
      <div>
        {{ item.fechaHora }}
      </div>
      <div>
        {{ item.nombreUsuario }}
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import { ref } from '@vue/composition-api'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import requirementService from '@/services/requirement.service'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  setup() {
    const { fetchRequirementLog } = requirementService()
    const requirementsLogs = ref([])
    // Methods

    const refetchRequirementLog = (requirementId, tableName) => {
      fetchRequirementLog(requirementId, tableName, data => {
        requirementsLogs.value = data
      })
    }
    const resolveVariant = event => {
      if (event === 'Creación\r\n') return 'primary'
      if (event === 'Modificación\r\n') return 'secondary'
      if (event === 'Eliminación\r\n') return 'warning'
      return ''
    }
    return {
      requirementsLogs,
      resolveVariant,
      refetchRequirementLog,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
