<template>
  <div>
    <b-alert
      show
      fade
      class="mt-1"
      variant="primary"
    >
      <div
        v-for="item in statusRAM"
        :key="item.id"
        class="alert-body"
      >
        <div
          block
          class="mb-1"
        >
          Status: {{ item.texto }}
        </div>
        <b-button-group>
          <b-button
            v-if="requirementFlowPossiblesChanges.requerimientoEstadoRetroceder"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="resolveStatusPreviousBtn(requirementFlowPossiblesChanges)"
            @click="onClickStatus(requirementFlowPossiblesChanges.requerimientoEstadoRetroceder.nombre,'Retroceder')"
          >
            <!-- @click="onAfterStatus" -->
            {{ requirementFlowPossiblesChanges.requerimientoEstadoRetroceder.nombre }}
          </b-button>
          <b-button
            v-if="requirementFlowPossiblesChanges.requerimientoEstadoAvanzar"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="onClickStatus(requirementFlowPossiblesChanges.requerimientoEstadoAvanzar.nombre,'Avanzar')"
          >
            <!-- :disabled="resolveStatusPreviousBtn(requirementFlowPossiblesChanges)" -->
            <!-- @click="onNextStatus" -->
            {{ requirementFlowPossiblesChanges.requerimientoEstadoAvanzar.nombre }}
          </b-button>
        </b-button-group>
      </div>
    </b-alert>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('Requirement.AddEdit.concludeRequirement')"
      :back-button-text="$t('Requirement.AddEdit.previousButton')"
      :next-button-text="$t('Requirement.AddEdit.following')"
      class="mb-3"
      @on-complete="formSubmitted"
      @on-change="LogbookValidation"
    >
      <!-- Requerimiento general -->
      <tab-content
        title="Datos generales"
        :before-change="validationGeneral"
      >
        <validation-observer
          ref="refGeneralValidationRules"
          tag="form"
        >
          <b-row>
            <!-- Numero accion mejora field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Numero de la acción"
                rules="required"
              >
                <b-form-group
                  label="Numero de la acción"
                  label-for="numero"
                >
                  <b-form-input
                    id="numero"
                    v-model="requirementData.numero"
                    :state="getValidationState(validationContext)"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Tipo accion mejora field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="tipoAccionMejora"
                rules="required"
              >
                <b-form-group
                  label="Tipo accion correctiva"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="requirementData.accionMejoraTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="actionsImprovementTypes"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha Field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Fecha"
                rules="required"
              >
                <b-form-group
                  label="Fecha"
                  label-for="fecha"
                >
                  <b-form-datepicker
                    id="fecha"
                    v-model="requirementData.fecha"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                    disabled
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Customer field-->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="cliente-interno-externo"
                rules="required"
              >
                <b-form-group
                  label="Cliente interno / externo"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="requirementData.clienteId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="customers"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Indicador Field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="indicador"
                rules="required"
              >
                <b-form-group
                  label="Indicador de la accion"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="requirementData.indicadorId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="indicators"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Area field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Area"
                rules="required"
              >
                <b-form-group
                  label="Area "
                  label-for="area"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="area"
                    v-model="requirementData.areaTrabajoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="workAreas"
                    @input="
                      refecthAreaManager(requirementData.areaTrabajoId),
                      refecthAreaEmployees(requirementData.areaTrabajoId)"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Responsable area field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="responsable-area"
                rules="required"
              >
                <b-form-group
                  label="Responsable de area"
                  label-for="responsable-area"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="responsable-area"
                    v-model="requirementData.empleadoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="areaManager"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Persona causante accion -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="persona-causante"
              >
                <b-form-group
                  label="Personas causantes"
                  label-for="persona-causante"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="persona-causante"
                    v-model="requirementData.causantes"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    multiple
                    :options="employeesArea"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Motivo accion field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Motivo"
                rules="required"
              >
                <b-form-group
                  label="Motivo accion (Problema) "
                  label-for="motivo-accion"
                >
                  <b-form-textarea
                    id="motivo-accion"
                    v-model="requirementData.motivo"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Accion mejora problema Field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="action-improvement-problem"
                rules="required"
              >
                <b-form-group
                  label="Accion mejora problema"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="requirementData.accionMejoraProblemaId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="actionsImprovementProblem"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- accion inmediata -->
      <tab-content
        title="Accion inmediata"
        :before-change="validationImmediateActionRequirement"
      >
        <validation-observer
          ref="refImmediateActionValidation"
          tag="form"
        >
          <immediate-action-requirement
            v-if="requirementData.requerimientoId"
            :requerimiento-id="requirementData.requerimientoId"
            :immediate-action-count.sync="immediateActionRequirementCount"
          />
        </validation-observer>
      </tab-content>
      <!--Origen Motivo -->
      <tab-content
        title="Razon de origen"
        :before-change="validationReasonOriginRequirement"
      >
        <validation-observer
          ref="refReasonOriginValidation"
          tag="form"
        >
          <reason-origin
            v-if="requirementData.requerimientoId"
            :requerimiento-id="requirementData.requerimientoId"
            :reason-origin-count.sync="reasonOriginCount"
          />
        </validation-observer>
      </tab-content>
      <!-- Aciones propuestas -->
      <tab-content
        title="Acciones propuestas"
        :before-change="validationProposedActionRequirement"
      >
        <validation-observer
          ref="refProposedActionsValidation"
          tag="form"
        >
          <proposed-actions
            v-if="requirementData.requerimientoId"
            :requerimiento-id="requirementData.requerimientoId"
            :proposed-action-count.sync="proposedActionCount"
          />
        </validation-observer>
      </tab-content>
      <!-- Documentos -->
      <tab-content
        title="Documentos"
        :before-change="validationDocuments"
      >
        <validation-observer
          ref="refDocumentsValidation"
          tag="form"
        >
          <documents
            v-if="requirementData.requerimientoId"
            :requerimiento-id="requirementData.requerimientoId"
            :documents-count.sync="documentsCount"
          />
        </validation-observer>
      </tab-content>

      <!-- Requerimientos de calidad -->
      <tab-content
        title="Requerimientos de calidad"
        :before-change="validationQualityRequirements"
      >
        <validation-observer
          ref="refQualityRequirements"
          tag="form"
        >
          <quality-requirements
            v-if="requirementData.requerimientoId"
            :requerimiento-id="requirementData.requerimientoId"
            :quality-requirement-count.sync="qualityRequirementCount"
          />
        </validation-observer>
      </tab-content>

      <!-- Evidences -->
      <tab-content
        title="Evidencias y cierre"
        :before-change="validationEvidence"
      >
        <validation-observer
          ref="refEvidenceValidation"
          tag="form"
        >
          <b-row>
            <!-- Numero accion mejora field -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Numero de la acción"
                rules="required"
              >
                <b-form-group
                  label="Es problema de alto riesgo para la empresa"
                  label-for="problema-alto riesgo"
                >
                  <b-form-checkbox
                    id="problema-alto riesgo"
                    v-model="requirementData.esProblemaAltoRiesgoParaEmpresa"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Es accion efectiva -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="accion efectiva"
                rules="required"
              >
                <b-form-group
                  label="Accion-efectiva"
                  label-for="Accion-efectiva"
                >
                  <b-form-checkbox
                    id="Accion-efectiva"
                    v-model="requirementData.esAccionEfectiva"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Consecuencias -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Consecuencias"
                rules="required"
              >
                <b-form-group
                  label="consecuencias"
                  label-for="Consecuencias"
                >
                  <b-form-textarea
                    id="Consecuencias"
                    v-model="requirementData.problemaAltoRiesgoParaEmpresaConsecuencias"
                    :state="getValidationState(validationContext)"
                    :disabled="requirementData.esProblemaAltoRiesgoParaEmpresa === false"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Comentarios -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="comentarios"
                rules="required"
              >
                <b-form-group
                  label="Comentarios"
                >
                  <b-form-textarea
                    v-model="requirementData.accionEfectivaComentarios"
                    :state="getValidationState(validationContext)"
                    :disabled="requirementData.esAccionEfectiva === false"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Evidencias acciones propuestas -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="evidencias"
                rules="required"
              >
                <b-form-group
                  label="evidencias acciones propuestas"
                >
                  <b-form-textarea
                    v-model="requirementData.evidenciaAccionesPropuestas"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Responsable verificaciones -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="responsable-verificaciones"
                rules="required"
              >
                <b-form-group
                  label="Responsable verificaciones"
                >
                  <v-select
                    v-model="requirementData.empleadoIdVerificacion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="employees"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha primera verificacion -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="primera-verificacion"
                rules="required"
              >
                <b-form-group
                  label="Fecha primera verificacion"
                  label-for="fecha-primera"
                >
                  <b-form-datepicker
                    id="fecha-primera"
                    v-model="requirementData.fechaVerificacionPrimera"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha segunda verificacion -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="segunda-verificacion"
                rules="required"
              >
                <b-form-group
                  label="Fecha segunda verificacion"
                  label-for="fecha-segunda"
                >
                  <b-form-datepicker
                    id="fecha-segunda"
                    v-model="requirementData.fechaVerificacionSegunda"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha verificacion Cierre -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="cierre-verificacion"
                rules="required"
              >
                <b-form-group
                  label="Fecha cierre verificacion"
                  label-for="fecha-segunda"
                >
                  <b-form-datepicker
                    id="fecha-cierra"
                    v-model="requirementData.fechaVerificacionCierre"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Responsable verificacion calidad -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="responsable-verificacion-calidad"
                rules="required"
              >
                <b-form-group
                  label="Responsable verificaciones de calidad"
                >
                  <v-select
                    v-model="requirementData.empleadoIdVerificacionCalidad"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="texto"
                    :reduce="option => option.id"
                    :options="employeesCalidad"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha primera verificacion -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="primera-verificacion-calidad"
                rules="required"
              >
                <b-form-group
                  label="Fecha primera verificacion calidad"
                  label-for="fecha-primera-calidad"
                >
                  <b-form-datepicker
                    id="fecha-primera-calidad"
                    v-model="requirementData.fechaVerificacionCalidadPrimera"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha segunda verificacion calidad -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="segunda-verificacion-calidad"
                rules="required"
              >
                <b-form-group
                  label="Fecha segunda verificacion calidad"
                  label-for="fecha-segunda-calidad"
                >
                  <b-form-datepicker
                    id="fecha-segunda-calidad"
                    v-model="requirementData.fechaVerificacionCalidadSegunda"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Fecha verificacion calidad Cierre -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="cierre-verificacion-calidad"
                rules="required"
              >
                <b-form-group
                  label="Fecha de cierre verificacion calidad "
                  label-for="fecha-cierre-calidad"
                >
                  <b-form-datepicker
                    id="fecha-cierre-calidad"
                    v-model="requirementData.fechaVerificacionCalidadCierre"
                    today-button
                    reset-button
                    close-button
                    locale="es"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="acciones-permanentes"
                rules="required"
              >
                <b-form-group
                  label="Acciones preventivas permanentes"
                >
                  <b-form-textarea
                    v-model="requirementData.accionesPreventivasPermanentes"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Mensaje Equipo -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="mensaje-equipo"
                rules="required"
              >
                <b-form-group
                  label="¡Felicite a su equipo!"
                >
                  <b-form-textarea
                    v-model="requirementData.mensajeEquipo"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <b-modal
        v-model="showRequirementStatusModal"
        title="Revisión"
        size="xl"
      >
        <validation-observer
          ref="refRequirementWorkFlowRules"
          tag="form"
        >
          <b-row>
            <!-- Detalle text area -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="detail"
                rules="required"
              >
                <b-form-group
                  label="Detalle"
                >
                  <b-form-textarea
                    v-model="requirementData.detalle"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Observaciones text area -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="observations"
                rules="required"
              >
                <b-form-group
                  label="Observaciones"
                >
                  <b-form-textarea
                    v-model="requirementData.observaciones"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              class="float-right"
              @click="validationRequirementWorkFlow(() => {
                onClickFlowStatus()
              })"
            >
              Enviar a {{ workFlow }}
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-button
        slot="finish"
        variant="success"
        :disabled="endingRequirement"
        @click="endingRequirement = true"
      >
        <b-spinner
          v-if="endingRequirement"
          small
          class="mr-1"
        />
        Finalizar requerimiento
      </b-button>
    </form-wizard>
    <b-card
      class="p-1"
    >
      <b-card-header
        class="mb-2"
      >
        Bitacora
      </b-card-header>
      <B-card-body>
        <requirement-edit-tab-log
          ref="refLogbook"
        />
      </B-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormInput,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BAlert,
  BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, requiredIf } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import requirementService from '@/services/requirement.service'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ImmediateActionRequirement from './RequirementAddEditImmediateActionRequirement.vue'
import ReasonOrigin from './RequirementAddEditReasonOrigin.vue'
import ProposedActions from './RequirementAddEditProposedActions.vue'
import RequirementEditTabLog from './RequirementEditTabLog.vue'
import Documents from './RequirementAddEditDocuments.vue'
import QualityRequirements from './RequirementAddEditQuailityRequeriments.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BAlert,
    BButtonGroup,
    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,
    ImmediateActionRequirement,
    ReasonOrigin,
    ProposedActions,
    Documents,
    QualityRequirements,
    RequirementEditTabLog,
  },
  setup() {
    /* Services */
    const {
      fetchRequirementById,
      updateRequirement,
      createRequirement,
      requirementWorkFlowNext,
      requirementWorkFlowAfter,
      requirementPossiblesChanges,
      fetchRequirementUserAllower,
      fetchActionImprovementTypeOptions,
      fetchCustomerOptions,
      fetchIndicatorOptions,
      fetchWorkAreaOptions,
      fetchActionImprovementProblemOptions,
      fetchAreaManager,
      fetchAreaEmployees,
      fetchEmployeeOptions,
      fetchEmployeeCalidadOptions,
      fetchStatusRAMOptions,
    } = requirementService()
    /* Refs */
    const refGeneralValidationRules = ref(null)
    const refImmediateActionValidation = ref(null)
    const refReasonOriginValidation = ref(null)
    const refProposedActionsValidation = ref(null)
    const refQualityRequirements = ref(null)
    const refEvidenceValidation = ref(null)
    const refLogbook = ref(null)
    const refRequirementWorkFlowRules = ref(null)

    const endingRequirement = ref(false)

    const guideRequired = ref(false)
    const damageRequired = ref(false)
    /* Data */
    const blankRequirementData = {
      requerimientoId: 0,
      numero: '-',
      fecha: new Date().toISOString(),
      motivo: null,
      causantes: [],
      evidenciaAccionesPropuestas: null,
      accionesPreventivasPermanentes: null,
      mensajeEquipo: null,
      esProblemaAltoRiesgoParaEmpresa: false,
      problemaAltoRiesgoParaEmpresaConsecuencias: null,
      esAccionEfectiva: false,
      accionEfectivaComentarios: null,
      archivado: null,
      clienteId: null,
      departamentoId: null,
      areaTrabajoId: null,
      indicadorId: null,
      accionMejoraTipoId: null,
      empleadoId: null,
      accionMejoraProblemaId: null,
      empleadoIdVerificacion: null,
      empleadoIdVerificacionCalidad: null,
      fechaVerificacionPrimera: null,
      fechaVerificacionSegunda: null,
      fechaVerificacionCierre: null,
      fechaVerificacionCalidadPrimera: null,
      fechaVerificacionCalidadSegunda: null,
      fechaVerificacionCalidadCierre: null,
      alta: null,
      ultimaModificacion: null,
      requerimientoEstadoId: null,
      detalle: null,
      observaciones: null,
    }

    const requirementData = ref({})
    const immediateActionRequirementCount = ref(0)
    const reasonOriginCount = ref(0)
    const proposedActionCount = ref(0)
    const documentsCount = ref(0)
    const qualityRequirementCount = ref(0)
    const actionsImprovementTypes = ref([])
    const customers = ref([])
    const indicators = ref([])
    const workAreas = ref([])
    const employeesArea = ref([])
    const employeesCalidad = ref([])
    const employees = ref([])
    const actionsImprovementProblem = ref([])
    const areaManager = ref([])
    const statusRAM = ref(null)
    const requirementFlowPossiblesChanges = ref()
    const showRequirementStatusModal = ref(false)
    const workFlowAction = ref(null)
    const workFlow = ref(null)
    const userAllower = ref(null)
    // Tables Names
    const tableNameRequirement = ref('accion_mejora_requerimiento')
    const tableNameImmediateAction = ref('accion_mejora_requerimiento_correccion_inmediata')
    const tableNameReasonOrigin = ref('accion_mejora_requerimiento_motivo_origen')
    const tableNameProposedActions = ref('accion_mejora_requerimiento_propuesta_prevencion_futura')
    const tableNameDocuments = ref('accion_mejora_requerimiento_documento')
    const tableNameQualityRequeriments = ref('accion_mejora_requerimiento_calidad')

    if (router.currentRoute.params.requerimientoId) {
      requirementPossiblesChanges(router.currentRoute.params.requerimientoId, data => {
        requirementFlowPossiblesChanges.value = data
      })
    }
    fetchActionImprovementTypeOptions(data => {
      actionsImprovementTypes.value = data
    })
    fetchCustomerOptions(data => {
      customers.value = data
    })
    fetchIndicatorOptions(data => {
      indicators.value = data
    })
    fetchWorkAreaOptions(data => {
      workAreas.value = data
    })
    fetchActionImprovementProblemOptions(data => {
      actionsImprovementProblem.value = data
    })
    fetchEmployeeOptions(data => {
      employees.value = data
    })
    fetchEmployeeCalidadOptions(data => {
      employeesCalidad.value = data
    })
    const refecthAreaManager = areaTrabajoId => {
      fetchAreaManager(data => {
        areaManager.value = data
      }, areaTrabajoId)
    }
    const refecthAreaEmployees = areaTrabajoId => {
      fetchAreaEmployees(data => {
        employeesArea.value = data.map(item => ({ empleadoId: item.id, texto: item.texto }))
      }, areaTrabajoId)
    }
    if (router.currentRoute.params.requerimientoId) {
      fetchStatusRAMOptions(router.currentRoute.params.requerimientoId, data => {
        statusRAM.value = data
      })
    }
    if (router.currentRoute.params.requerimientoId) {
      fetchRequirementById(router.currentRoute.params.requerimientoId, data => {
        requirementData.value = data
        requirementData.value.causantes = requirementData.value.causantes.map(map => ({ empleadoId: map.empleadoId, texto: map.empleado?.nombreEmpleado }))
        refecthAreaEmployees(requirementData.value.areaTrabajoId)
        refecthAreaManager(requirementData.value.areaTrabajoId)
        if (router.currentRoute.params.requerimientoId) {
          refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameRequirement.value)
        }
      })
    } else {
      requirementData.value = JSON.parse(JSON.stringify(blankRequirementData))
    }

    const filesTableFields = [
      {
        key: 'nombreDocumento',
        label: i18n.t('AppPrevious.AddEdit.documentName'),
        sortable: false,
      },
      {
        key: 'previoDocumentoTipo.nombre',
        label: i18n.t('AppPrevious.AddEdit.typePreviousDocument'),
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    /* Events */
    const onSubmit = () => {
      if (requirementData.value.requerimientoId !== 0) {
        updateRequirement(requirementData.value, () => {
          router.push({ name: 'apps-requirement-list' })
        })
      } else {
        createRequirement(requirementData.value, () => {
          router.push({ name: 'apps-requirement-list' })
        })
      }
    }
    const formSubmitted = () => {
      endingRequirement.value = true
      router.push({ name: 'apps-requirement-list' })
    }
    const LogbookValidation = (prevIndex, nextIndex) => {
      if (router.currentRoute.params.requerimientoId) {
        switch (nextIndex) {
          case 0:
          case 6:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameRequirement.value)
            break
          case 1:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameImmediateAction.value)
            break
          case 2:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameReasonOrigin.value)
            break
          case 3:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameProposedActions.value)
            break
          case 4:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameDocuments.value)
            break
          case 5:
            refLogbook.value.refetchRequirementLog(router.currentRoute.params.requerimientoId, tableNameQualityRequeriments.value)
            break
          default:
            break
        }
      }
    }
    const onClickStatus = (workFlowStep, FlowAction) => {
      workFlowAction.value = FlowAction
      workFlow.value = workFlowStep
      showRequirementStatusModal.value = true
    }
    const onClickFlowStatus = () => {
      if (workFlowAction.value === 'Avanzar') {
        requirementWorkFlowNext({
          requerimientoId: requirementData.value.requerimientoId,
          detalle: requirementData.value.detalle,
          observaciones: requirementData.value.observaciones,
        })
        formSubmitted()
      } else if (workFlowAction.value === 'Retroceder') {
        requirementWorkFlowAfter({
          requerimientoId: requirementData.value.requerimientoId,
          detalle: requirementData.value.detalle,
          observaciones: requirementData.value.observaciones,
        })
        formSubmitted()
      }
    }
    /* Validations */
    const validationGeneral = () => new Promise((resolve, reject) => {
      refGeneralValidationRules.value.validate().then(success => {
        if (success) {
          if (requirementData.value.requerimientoId !== 0) {
            updateRequirement({
              requerimientoId: requirementData.value.requerimientoId,
              numero: requirementData.value.numero,
              fecha: requirementData.value.fecha,
              motivo: requirementData.value.motivo,
              archivado: 0,
              empleadoIdVerificacionCalidad: requirementData.value.empleadoIdVerificacionCalidad,
              areaTrabajoId: requirementData.value.areaTrabajoId,
              indicadorId: requirementData.value.indicadorId,
              accionMejoraTipoId: requirementData.value.accionMejoraTipoId,
              accionMejoraProblemaId: requirementData.value.accionMejoraTipoId,
              empleadoId: requirementData.value.empleadoId,
              clienteId: requirementData.value.clienteId,
              requirimientoEstadoId: 0,
              esProblemaAltoRiesgoParaEmpresa: requirementData.value.esProblemaAltoRiesgoParaEmpresa,
              esAccionEfectiva: requirementData.value.esAccionEfectiva,
              problemaAltoRiesgoParaEmpresaConsecuencias: requirementData.value.problemaAltoRiesgoParaEmpresaConsecuencias,
              accionEfectivaComentarios: requirementData.value.accionEfectivaComentarios,
              evidenciaAccionesPropuestas: requirementData.value.evidenciaAccionesPropuestas,
              empleadoIdVerificacion: requirementData.value.empleadoIdVerificacion,
              fechaVerificacionPrimera: requirementData.value.fechaVerificacionPrimera,
              fechaVerificacionSegunda: requirementData.value.fechaVerificacionSegunda,
              fechaVerificacionCierre: requirementData.value.fechaVerificacionCierre,
              fechaVerificacionCalidadPrimera: requirementData.value.fechaVerificacionCalidadPrimera,
              fechaVerificacionCalidadSegunda: requirementData.value.fechaVerificacionCalidadSegunda,
              fechaVerificacionCalidadCierre: requirementData.value.fechaVerificacionCalidadCierre,
              accionesPreventivasPermanentes: requirementData.value.accionesPreventivasPermanentes,
              mensajeEquipo: requirementData.value.mensajeEquipo,

            }, () => {
            })
          } else {
            createRequirement(requirementData.value, data => {
              requirementData.value.requerimientoId = data.requerimientoId
            })
          }
          resolve(true)
        } else {
          reject()
        }
      })
    })
    const validationImmediateActionRequirement = () => new Promise((resolve, reject) => {
      if (immediateActionRequirementCount.value > 0) {
        resolve(true)
      } else {
        reject()
      }
    })
    const validationReasonOriginRequirement = () => new Promise((resolve, reject) => {
      if (reasonOriginCount.value > 0) {
        resolve(true)
      } else {
        reject()
      }
    })

    const validationProposedActionRequirement = () => new Promise((resolve, reject) => {
      if (proposedActionCount.value > 0) {
        resolve(true)
      } else {
        reject()
      }
    })

    const validationDocuments = () => new Promise((resolve, reject) => {
      if (documentsCount.value > 0) {
        resolve(true)
      } else {
        reject()
      }
    })

    const validationQualityRequirements = () => new Promise((resolve, reject) => {
      if (qualityRequirementCount.value > 0) {
        resolve(true)
      } else {
        reject()
      }
    })

    const validationEvidence = () => new Promise((resolve, reject) => {
      refGeneralValidationRules.value.validate().then(success => {
        if (success) {
          if (requirementData.value.requerimientoId !== 0) {
            updateRequirement({
              requerimientoId: requirementData.value.requerimientoId,
              numero: requirementData.value.numero,
              fecha: requirementData.value.fecha,
              motivo: requirementData.value.motivo,
              archivado: 0,
              areaTrabajoId: requirementData.value.areaTrabajoId,
              indicadorId: requirementData.value.indicadorId,
              accionMejoraTipoId: requirementData.value.accionMejoraTipoId,
              accionMejoraProblemaId: requirementData.value.accionMejoraTipoId,
              empleadoId: requirementData.value.empleadoId,
              clienteId: requirementData.value.clienteId,
              requirimientoEstadoId: 0,
              esProblemaAltoRiesgoParaEmpresa: requirementData.value.esProblemaAltoRiesgoParaEmpresa,
              esAccionEfectiva: requirementData.value.esAccionEfectiva,
              problemaAltoRiesgoParaEmpresaConsecuencias: requirementData.value.problemaAltoRiesgoParaEmpresaConsecuencias,
              accionEfectivaComentarios: requirementData.value.accionEfectivaComentarios,
              evidenciaAccionesPropuestas: requirementData.value.evidenciaAccionesPropuestas,
              empleadoIdVerificacion: requirementData.value.empleadoIdVerificacion,
              fechaVerificacionPrimera: requirementData.value.fechaVerificacionPrimera,
              fechaVerificacionSegunda: requirementData.value.fechaVerificacionSegunda,
              fechaVerificacionCierre: requirementData.value.fechaVerificacionCierre,
              empleadoIdVerificacionCalidad: requirementData.value.empleadoIdVerificacionCalidad,
              fechaVerificacionCalidadPrimera: requirementData.value.fechaVerificacionCalidadPrimera,
              fechaVerificacionCalidadSegunda: requirementData.value.fechaVerificacionCalidadSegunda,
              fechaVerificacionCalidadCierre: requirementData.value.fechaVerificacionCalidadCierre,
              accionesPreventivasPermanentes: requirementData.value.accionesPreventivasPermanentes,
              mensajeEquipo: requirementData.value.mensajeEquipo,

            }, () => {
            })
          } else {
            createRequirement(requirementData.value, data => {
              requirementData.value.requerimientoId = data.requerimientoId
            })
          }
          resolve(true)
        } else {
          reject()
        }
      })
    })
    const validationRequirementWorkFlow = callback => {
      refRequirementWorkFlowRules.value.validate().then(success => {
        if (success) {
          callback()
        }
      })
    }

    const resolveStatusPreviousBtn = status => {
      fetchRequirementUserAllower(status.flujoTrabajoId, requirementData.value.areaTrabajoId, data => {
        userAllower.value = data
      })
    }
    return {
      refGeneralValidationRules,
      refImmediateActionValidation,
      refReasonOriginValidation,
      refProposedActionsValidation,
      refQualityRequirements,
      refEvidenceValidation,
      refRequirementWorkFlowRules,
      refLogbook,

      guideRequired,
      damageRequired,

      requirementData,
      immediateActionRequirementCount,
      reasonOriginCount,
      proposedActionCount,
      documentsCount,
      qualityRequirementCount,
      actionsImprovementTypes,
      customers,
      indicators,
      workAreas,
      employeesArea,
      employeesCalidad,
      employees,
      statusRAM,
      requirementFlowPossiblesChanges,
      actionsImprovementProblem,
      areaManager,
      refecthAreaManager,
      refecthAreaEmployees,
      showRequirementStatusModal,
      workFlowAction,
      workFlow,
      // Tables Names
      tableNameRequirement,
      tableNameImmediateAction,
      tableNameReasonOrigin,
      tableNameProposedActions,
      tableNameDocuments,
      tableNameQualityRequeriments,

      required,
      requiredIf,

      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
      filesTableFields,
      endingRequirement,
      LogbookValidation,
      onClickFlowStatus,
      onClickStatus,

      formSubmitted,
      validationGeneral,
      validationImmediateActionRequirement,
      validationReasonOriginRequirement,
      validationProposedActionRequirement,
      validationDocuments,
      validationQualityRequirements,
      validationEvidence,
      validationRequirementWorkFlow,
      resolveStatusPreviousBtn,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
