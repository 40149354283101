import axios from '@axios'
import common from '@/libs/common'

export default function PreviousService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________REQUIREMENT___________________________
  // __________________________________________________________________________
  const fetchReportFile = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/Reporte`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        throwError(error)
      })
  }
  const fetchRequirementById = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createRequirement = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRequirement = (data, callback) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/${data.requerimientoId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const partialUpdateRequirement = (requerimientoId, data, callback) => {
    axios
      .patch(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const requirementWorkFlowNext = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/${data.requerimientoId}/FlujoTrabajo/Avanzar`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const requirementWorkFlowAfter = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/${data.requerimientoId}/FlujoTrabajo/Retroceder`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const requirementPossiblesChanges = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/FlujoTrabajo/EstadosCambioPosibles`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRequirementUserAllower = (workFlowId, areaTrabajoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/UsuarioPermitido/${workFlowId}/${areaTrabajoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________OPTIONS__________________
  // __________________________________________________________________________
  const fetchActionImprovementTypeOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/AccionMejoraTipo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchActionImprovementProblemOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/AccionesMejoraProblema`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchCustomerOptions = callback => {
    axios
      .get(`${API_URL}/Clientes/Opciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchIndicatorOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/Indicador`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchWorkAreaOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/RecursosHumanosAreaTrabajo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchEmployeeOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/Empleados`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchEmployeeCalidadOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/EmpleadoCalidad`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchAreaManager = (callback, areaTrabajoId) => {
    axios
      .get(`${API_URL}/Opciones/ResponsableArea/${areaTrabajoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchAreaEmployees = (callback, areaTrabajoId) => {
    axios
      .get(`${API_URL}/Opciones/EmpleadosArea/${areaTrabajoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchRequerimientDocuments = callback => {
    axios
      .get(`${API_URL}/Opciones/RequerimientoDocumento`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchStatusRAMOptions = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/Opciones/StatusRAM/${requerimientoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________IMMEDIATEACTIONREQUIREMENT________
  // __________________________________________________________________________
  const fetchImmediateActionRequirement = (requerimientoId, data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/CorreccionesInmediatas`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createImmediateActionRequirement = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/CorreccionInmediata`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateImmediateActionRequirement = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/CorreccionInmediata/${data.requerimientoCorreccionInmediataId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteImmediateActionRequirement = (requerimientoCorreccionInmediataId, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/CorreccionInmediata/${requerimientoCorreccionInmediataId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________Reason Origin_____________________________
  // _________________________________________________________________________
  const fetchReasonOrigin = (requerimientoId, data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/MotivosOrigen`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createReasonOrigin = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/MotivoOrigen`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateReasonOrigin = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/MotivoOrigen/${data.requerimientoMotivoOrigenId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteReasonOrigin = (requerimientoMotivoOrigenId, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/MotivoOrigen/${requerimientoMotivoOrigenId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________Proposed Actions___________________________
  // _________________________________________________________________________
  const fetchProposedActions = (requerimientoId, data, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/PropuestasPrevencionFutura`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createProposedActions = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/PropuestaPrevencionFutura`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateProposedActions = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/PropuestaPrevencionFutura/${data.requerimientoPropuestaPrevencionFuturaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteProposedActions = (requerimientoPropuestaPrevencionFuturaId, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/PropuestaPrevencionFutura/${requerimientoPropuestaPrevencionFuturaId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________Documents_______________________________________________________________
  // ____________________________________________________________________________________________________________
  const fetchRequirementDocuments = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/Documentos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createRequirementDocument = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/Documento`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateRequirementDocument = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/Documento/${data.requerimientoDocumentoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteRequirementDocument = (requerimientoDocumentoId, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/Documento/${requerimientoDocumentoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________QualityRequirement_______________________________________________________________
  // ____________________________________________________________________________________________________________
  const fetchQualityRequirements = (requerimientoId, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Requerimiento/${requerimientoId}/RequerimientosCalidad`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createQualityRequirement = (data, callback) => {
    axios
      .post(`${API_URL}/AccionesMejora/Requerimiento/RequerimientoCalidad`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateQualityRequirement = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/AccionesMejora/Requerimiento/RequerimientoCalidad/${data.requerimientoCalidadId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteQualityRequirement = (requerimientoCalidadId, callback) => {
    axios
      .delete(`${API_URL}/AccionesMejora/Requerimiento/RequerimientoCalidad/${requerimientoCalidadId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchQualityRequerimentType = callback => {
    axios
      .get(`${API_URL}/Opciones/RequerimientocalidadTipo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // --------------------------------------------------------------------------------------------------------------
  //  ________________________________Logbook_______________________________________________________________
  // ____________________________________________________________________________________________________________
  const fetchRequirementLog = (requerimientoId, tableName, callback) => {
    axios
      .get(`${API_URL}/AccionesMejora/Bitacora/Requerimiento/${requerimientoId}/Tabla/${tableName}`)
      .then(response => {
        const { eventos } = response.data
        callback(eventos)
      })
      .catch(error => throwError(error))
  }
  return {
    // REQUIREMENT
    fetchRequirementById,
    updateRequirement,
    createRequirement,
    partialUpdateRequirement,
    requirementWorkFlowNext,
    requirementWorkFlowAfter,
    requirementPossiblesChanges,
    fetchRequirementUserAllower,
    // OPTIONS
    fetchActionImprovementTypeOptions,
    fetchCustomerOptions,
    fetchIndicatorOptions,
    fetchWorkAreaOptions,
    fetchEmployeeOptions,
    fetchEmployeeCalidadOptions,
    fetchActionImprovementProblemOptions,
    fetchRequerimientDocuments,
    fetchStatusRAMOptions,
    // IMMEDIATEACTIONREQUIREMENT
    fetchImmediateActionRequirement,
    createImmediateActionRequirement,
    deleteImmediateActionRequirement,
    updateImmediateActionRequirement,
    fetchAreaManager,
    fetchAreaEmployees,
    // Reason Origin
    fetchReasonOrigin,
    createReasonOrigin,
    updateReasonOrigin,
    deleteReasonOrigin,
    // Proposed Actions
    fetchProposedActions,
    createProposedActions,
    updateProposedActions,
    deleteProposedActions,
    // Documents
    fetchRequirementDocuments,
    createRequirementDocument,
    updateRequirementDocument,
    deleteRequirementDocument,
    // quality requirement
    fetchQualityRequirements,
    createQualityRequirement,
    updateQualityRequirement,
    deleteQualityRequirement,
    fetchQualityRequerimentType,
    // Report
    fetchReportFile,
    // Log
    fetchRequirementLog,
  }
}
