<template>
  <div>
    <b-alert
      v-if="qualityRequirements.length < 1"
      show
      dismissible
      fade
      class="mt-1"
      variant="warning"
    >
      <div class="alert-body">
        Debe capturar al menos un documento
      </div>
    </b-alert>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Lists.Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('Lists.Entries') }}</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Lists.Search')"
            />
            <b-button
              variant="primary"
              @click="onNewQualityRequierement"
            >
              <span class="text-nowrap">Nuevo requerimiento de calidad</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refQualityRequirementTable"
      responsive
      :items="refetchQualityRequirement"
      :fields="qualityRequirementFields"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="onSelectQualityRequirement(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onDeleteQualityRequirement(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalQualityRequirement"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-if="qualityRequirementSelected"
      id="modal-immediateActionRequirement"
      v-model="showEditQualityRequirementModal"
      title="Nueva accion inmediata"
      size="xl"
    >
      <validation-observer
        ref="refQualityRequirementRules"
        tag="form"
      >
        <b-row>
          <!-- Immediate Action input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="AccionInmediata"
              rules="required"
            >
              <b-form-group
                label="Documento tipo"
              >
                <v-select
                  v-model="qualityRequirementSelected.requerimientoCalidadTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="qualityRequirementsType"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- aplica input -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Aplica"
            >
              <b-form-checkbox
                v-model="qualityRequirementSelected.aplica"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="qualityRequirementSelected.requerimientoCalidadId === 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationQualityRequirement(() => {
              onAddQualityRequierement()
            })"
          >
            Guardar requerimiento de calidad
          </b-button>
          <b-button
            v-if="qualityRequirementSelected.requerimientoCalidadId > 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationQualityRequirement(() => {
              onEditQualityRequirement()
            })"
          >
            Actualizar requerimiento calidad
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import requirementService from '@/services/requirement.service'
import i18n from '@/libs/i18n'

export default {

  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    // BModal,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BFormCheckbox,
    // BFormTextarea,
    // BImg,
    BAlert,

    // FileUploader,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    requerimientoId: {
      type: Number,
      required: true,
    },
    qualityRequirementCount: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Refs */
    const {
      fetchQualityRequirements,
      createQualityRequirement,
      deleteQualityRequirement,
      updateQualityRequirement,
      fetchQualityRequerimentType,
    } = requirementService()
    const refQualityRequirementTable = ref(null)
    const refQualityRequirementRules = ref(null)

    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const searchQuery = ref('')
    const sortBy = ref('requerimientoCalidadId')
    const isSortDirDesc = ref(false)
    /* Data */
    const totalQualityRequirement = ref(0)
    const qualityRequirements = ref([])
    const qualityRequirementsType = ref([])

    const blankQualityRequirement = {
      requerimientoCalidadId: 0,
      requerimientoId: props.requerimientoId,
      requerimientoCalidadTipoId: 0,
      aplica: false,
    }

    const qualityRequirementSelected = ref(JSON.parse(JSON.stringify(blankQualityRequirement)))
    const qualityRequirementFields = [
      {
        key: 'requerimientoCalidadTipo.nombre',
        label: 'Requerimiento calidad tipo',
        sortable: false,
      },
      {
        key: 'aplica',
        label: 'Aplica',
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]
    const refetchQualityRequirement = (ctx, callback) => {
      fetchQualityRequirements(props.requerimientoId, data => {
        qualityRequirements.value = data.requerimientosCalidad
        totalQualityRequirement.value = data.total
        emit('update:qualityRequirementCount', totalQualityRequirement.value)
        callback(qualityRequirements.value)
      })
    }
    fetchQualityRequerimentType(data => {
      qualityRequirementsType.value = data
    })
    const dataMeta = computed(() => {
      const localItemsCount = refQualityRequirementTable.value ? refQualityRequirementTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalQualityRequirement.value,
      }
    })

    const refreshData = () => {
      refQualityRequirementTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refreshData()
    })

    /* UI */
    const showEditQualityRequirementModal = ref(false)
    const savingChanges = ref(false)

    const onNewQualityRequierement = () => {
      qualityRequirementSelected.value = JSON.parse(JSON.stringify(blankQualityRequirement))
      showEditQualityRequirementModal.value = true
    }
    const onAddQualityRequierement = () => {
      savingChanges.value = true
      createQualityRequirement(qualityRequirementSelected.value, data => {
        qualityRequirementSelected.value = data
        savingChanges.value = false
        showEditQualityRequirementModal.value = false
        refreshData()
      })
    }
    const onSelectQualityRequirement = item => {
      qualityRequirementSelected.value = item
      showEditQualityRequirementModal.value = true
    }

    const onEditQualityRequirement = () => {
      savingChanges.value = true
      updateQualityRequirement(qualityRequirementSelected.value)
        .then(() => {
          savingChanges.value = false
          showEditQualityRequirementModal.value = false
          qualityRequirementSelected.value = null
          refreshData()
        })
    }
    const onDeleteQualityRequirement = item => {
      deleteQualityRequirement(item.requerimientoCalidadId, () => {
        refreshData()
      })
    }
    const {
      getValidationState,
    } = formValidation()

    const validationQualityRequirement = callback => {
      refQualityRequirementRules.value.validate().then(success => {
        if (success) {
          callback()
        }
      })
    }
    return {
      refQualityRequirementTable,
      refQualityRequirementRules,

      perPageOptions,
      perPage,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      qualityRequirementSelected,
      qualityRequirementFields,
      qualityRequirements,
      qualityRequirementsType,
      totalQualityRequirement,

      dataMeta,

      refetchQualityRequirement,

      required,
      getValidationState,

      showEditQualityRequirementModal,

      savingChanges,

      onNewQualityRequierement,
      onAddQualityRequierement,
      onSelectQualityRequirement,
      onEditQualityRequirement,
      onDeleteQualityRequirement,
      validationQualityRequirement,
    }
  },
}
</script>

<style>

</style>
