<template>
  <div>
    <b-alert
      v-if="requirementDocuments.length < 1"
      show
      dismissible
      fade
      class="mt-1"
      variant="warning"
    >
      <div class="alert-body">
        Debe capturar al menos un documento
      </div>
    </b-alert>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('Lists.Show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('Lists.Entries') }}</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Lists.Search')"
            />
            <b-button
              variant="primary"
              @click="onNewRequierementDocuments"
            >
              <span class="text-nowrap">Nuevo documento</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refRequirementDocumentsTable"
      responsive
      :items="refetchRequirementDocuments"
      :fields="requirementDocumentsFields"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('Lists.NoMatchingRecordsFound')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="onSelectRequirementDocuments(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onDeleteRequirementDocuments(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRequirementDocuments"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-if="requirementDocumentsSelected"
      id="modal-immediateActionRequirement"
      v-model="showEditRequirementDocumentsModal"
      title="Nueva accion inmediata"
      size="xl"
    >
      <validation-observer
        ref="refRequirementDocumentsRules"
        tag="form"
      >
        <b-row>
          <!-- Immediate Action input -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="AccionInmediata"
              rules="required"
            >
              <b-form-group
                label="Documento tipo"
              >
                <v-select
                  v-model="requirementDocumentsSelected.requerimientoDocumentoTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :reduce="option => option.id"
                  :options="documentsType"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- cost applies check -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Nombre"
            >
              <b-form-input
                v-model="requirementDocumentsSelected.nombre"
              />
            </b-form-group>
          </b-col>
          <!-- cost input -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Numero de trazabilidad"
            >
              <b-form-input
                v-model="requirementDocumentsSelected.numeroTrazabilidad"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="requirementDocumentsSelected.requerimientoDocumentoId === 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationRequirementDocuments(() => {
              onAddRequierementDocuments()
            })"
          >
            Guardar documento
          </b-button>
          <b-button
            v-if="requirementDocumentsSelected.requerimientoDocumentoId > 0 && !savingChanges"
            variant="primary"
            class="float-right"
            @click="validationRequirementDocuments(() => {
              onEditRequirementDocuments()
            })"
          >
            Actualizar accion inmediata
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  // BModal,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInvalidFeedback,
  // BFormCheckbox,
  // BFormTextarea,
  // BImg,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import FileUploader from '@/components/FileUploader.vue'
import { ref, watch, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import requirementService from '@/services/requirement.service'
// import optionService from '@/services/option.service'
import i18n from '@/libs/i18n'

export default {

  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    // BModal,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    // BFormCheckbox,
    // BFormTextarea,
    // BImg,
    BAlert,

    // FileUploader,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    requerimientoId: {
      type: Number,
      required: true,
    },
    documentsCount: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Refs */
    const {
      fetchRequirementDocuments,
      createRequirementDocument,
      deleteRequirementDocument,
      updateRequirementDocument,
      fetchRequerimientDocuments,
    } = requirementService()
    const refRequirementDocumentsTable = ref(null)
    const refRequirementDocumentsRules = ref(null)

    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const searchQuery = ref('')
    const sortBy = ref('requerimientoDocumentoId')
    const isSortDirDesc = ref(false)
    /* Data */
    const totalRequirementDocuments = ref(0)
    const requirementDocuments = ref([])
    const documentsType = ref([])

    const blankRequirementDocumentData = {
      requerimientoDocumentoId: 0,
      requerimientoId: props.requerimientoId,
      requerimientoDocumentoTipoId: 0,
      nombre: '',
      numeroTrazabilidad: '',
    }

    const requirementDocumentsSelected = ref(JSON.parse(JSON.stringify(blankRequirementDocumentData)))
    const requirementDocumentsFields = [
      {
        key: 'nombre',
        label: 'Nombre',
        sortable: false,
      },
      {
        key: 'numeroTrazabilidad',
        label: 'Numero Trazabilidad',
        sortable: false,
      },
      {
        key: 'requerimientoDocumentoTipo.nombre',
        label: 'Documento Tipo',
        sortable: false,
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
        sortable: false,
      },
    ]
    const refetchRequirementDocuments = (ctx, callback) => {
      fetchRequirementDocuments(props.requerimientoId, data => {
        requirementDocuments.value = data.documentos
        totalRequirementDocuments.value = data.total
        emit('update:documents-count', totalRequirementDocuments.value)
        callback(requirementDocuments.value)
      })
    }
    fetchRequerimientDocuments(data => {
      documentsType.value = data
    })
    const dataMeta = computed(() => {
      const localItemsCount = refRequirementDocumentsTable.value ? refRequirementDocumentsTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRequirementDocuments.value,
      }
    })

    const refreshData = () => {
      refRequirementDocumentsTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refreshData()
    })

    /* UI */
    const showEditRequirementDocumentsModal = ref(false)
    const savingChanges = ref(false)

    const onNewRequierementDocuments = () => {
      requirementDocumentsSelected.value = JSON.parse(JSON.stringify(blankRequirementDocumentData))
      showEditRequirementDocumentsModal.value = true
    }
    const onAddRequierementDocuments = () => {
      savingChanges.value = true
      createRequirementDocument(requirementDocumentsSelected.value, data => {
        requirementDocumentsSelected.value = data
        savingChanges.value = false
        showEditRequirementDocumentsModal.value = false
        refreshData()
      })
    }
    const onSelectRequirementDocuments = item => {
      requirementDocumentsSelected.value = item
      showEditRequirementDocumentsModal.value = true
    }

    const onEditRequirementDocuments = () => {
      savingChanges.value = true
      updateRequirementDocument(requirementDocumentsSelected.value)
        .then(() => {
          savingChanges.value = false
          showEditRequirementDocumentsModal.value = false
          requirementDocumentsSelected.value = null
          refreshData()
        })
    }
    const onDeleteRequirementDocuments = item => {
      deleteRequirementDocument(item.requerimientoDocumentoId, () => {
        refreshData()
      })
    }
    const {
      getValidationState,
    } = formValidation()

    const validationRequirementDocuments = callback => {
      refRequirementDocumentsRules.value.validate().then(success => {
        if (success) {
          callback()
        }
      })
    }
    return {
      refRequirementDocumentsTable,
      refRequirementDocumentsRules,

      perPageOptions,
      perPage,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      requirementDocumentsSelected,
      requirementDocumentsFields,
      requirementDocuments,
      documentsType,
      totalRequirementDocuments,
      dataMeta,

      refetchRequirementDocuments,

      required,
      getValidationState,

      showEditRequirementDocumentsModal,

      savingChanges,

      onNewRequierementDocuments,
      onAddRequierementDocuments,
      onSelectRequirementDocuments,
      onEditRequirementDocuments,
      onDeleteRequirementDocuments,
      validationRequirementDocuments,
    }
  },
}
</script>

<style>

</style>
